import {
  F1ActivityNames,
  F2ActivityNames,
  F3ActivityNames,
} from '@core/activities';
import { UserData, UserDataUpdate } from '@core/users/users';
import { addDays } from '@core/utils/dates';
import { MIN_DAYS_FOR_F2, MIN_DAYS_FOR_F3 } from '@core/utils/env';
import { WellnessCheckValues } from '@core/wellness';
import { ClientRecording } from '@web/components/recorder/recorder';
import { createActivity } from '@web/features/activities/create-activity';
import { uploadRecording } from '@web/features/recordings/api/upload-recording';
import * as React from 'react';
import { TMApi } from '@web/services/tmapi';
import { UserDispatchContext, UserStateContext } from './user.context';
import { UserDispatchTypes } from './user.state';

export function useUserData() {
  const state = React.useContext(UserStateContext);
  const dispatch = React.useContext(UserDispatchContext);

  if (typeof state === 'undefined' || !dispatch) {
    throw new Error(
      'useUserData must be used inside of a `UserStateContext.Provider`. Please wrap this component in an `UserStateContext.Provider` component',
    );
  }

  const { userData, userStatus: f1Status } = state;

  const { isDemo } = f1Status?.global || {};
  const { hasMissedASession } = f1Status?.f1 || {};
  const { canWater } = f1Status?.f3 || {};

  /**
   * # updateUserDocument
   *
   * @param uid - a string representing the user's key around the db
   * @param details - an array representing key:value pairs to update/add to the user's document
   */
  const updateUserDocument = React.useCallback(
    async (uid: string, details: UserDataUpdate) => {
      // turn the `details` array into an object
      const update = details.reduce(
        (prev, { key, value }) => ({ ...prev, [key]: value }),
        {} as Partial<UserData>,
      );
      const tmapi = new TMApi();
      await tmapi.updateUserDocument(update);

      return {
        ...userData,
        ...update,
      } as UserData;
    },

    [userData],
  );

  React.useEffect(() => {
    const shouldResetUser =
      hasMissedASession && userData?.f1_consecutive_days !== 0;

    if (shouldResetUser && !!userData) {
      const update: UserDataUpdate = [
        { key: 'f1_status', value: 'FLOW_COMPLETE' },
        { key: 'f1_consecutive_days', value: 0 },
      ];

      updateUserDocument(userData.created_by, update);
    }
  }, [hasMissedASession, updateUserDocument, userData]);

  React.useEffect(() => {
    if (canWater && !!userData && userData.f3_water_level > 0) {
      const update: UserDataUpdate = [{ key: 'f3_water_level', value: 0 }];

      updateUserDocument(userData.created_by, update);
    }
  }, [canWater, updateUserDocument, userData]);

  const loginAsUser = (overrideUid: string) =>
    dispatch({
      type: UserDispatchTypes.ON_LOGIN_AS_USER,
      payload: { overrideUid },
    });

  const toggleFunctionalityNotifications = React.useCallback(
    async (
      functionality: keyof Pick<
        UserData,
        | 'f1_notifications_enabled'
        | 'f2_notifications_enabled'
        | 'f3_notifications_enabled'
      >,
    ) => {
      if (!userData) {
        throw new Error('User not found. Please login.');
      }

      const isValid = typeof userData[functionality] === 'boolean';

      if (!isValid) {
        throw new Error(`${functionality} is not a boolean`);
      }

      const newValue = !userData[functionality];

      const update: UserDataUpdate = [
        { key: 'notification_type', value: 'EMAIL' }, // TODO: remove/change this when we have other notification types, default to email for now
        { key: functionality, value: newValue },
      ];

      return updateUserDocument(userData.created_by, update);
    },
    [updateUserDocument, userData],
  );

  const onOnboardFinish = React.useCallback(async () => {
    if (typeof userData === 'undefined') {
      throw new Error('User not found. Please login.');
    }

    const update: UserDataUpdate = [{ key: 'has_onboarded', value: true }];

    await updateUserDocument(userData.created_by, update);

    return createActivity({
      uid: userData.created_by,
      config: { name: F1ActivityNames.ONBOARD },
    });
  }, [updateUserDocument, userData]);

  // a function that logs the user's activity and progresses them to the next task
  const onListenFinish = React.useCallback(
    async ({
      recordingId,
      shouldSave = false,
      name = null,
    }: {
      recordingId: string;
      shouldSave: boolean;
      name?: string | null;
    }) => {
      if (typeof userData === 'undefined') {
        throw new Error('User Data is not defined. Please login.');
      }

      const update: UserDataUpdate = [
        { key: 'f1_has_listened', value: true },
        { key: 'f1_status', value: 'FLOW_1' },
      ];

      if (shouldSave) {
        const tmapi = new TMApi();
        await tmapi.updateRecording(recordingId, {
          status: 'SAVED',
          name: name,
        });
      }

      /** Update user's document */
      await updateUserDocument(userData.created_by, update);

      await createActivity({
        uid: userData.created_by,
        config: {
          name: F1ActivityNames.LISTEN,
          details: { recordingId, shouldSave, name },
        },
      });
    },
    [updateUserDocument, userData],
  );

  const onRecordFinish = React.useCallback(
    async (recording: ClientRecording) => {
      if (typeof userData === 'undefined') {
        throw new Error('User Data is not defined. Please login.');
      }

      const isFirstDay = userData.f1_rolling_days === 0;
      let didUserUnlockF2 = userData.f1_rolling_days === MIN_DAYS_FOR_F2 - 1;
      let didUserUnlockF3 = userData.f1_rolling_days === MIN_DAYS_FOR_F3 - 1;

      if (isDemo) {
        didUserUnlockF2 = userData.f1_rolling_days === 0;
        didUserUnlockF3 = userData.f1_rolling_days === 1;
      }

      const { recordingId } = await uploadRecording(userData, recording, null);

      /** update user document */
      const update: UserDataUpdate = [
        { key: 'f1_status', value: 'FLOW_COMPLETE' },
        { key: 'f1_last_recording', value: recordingId },
        { key: 'f1_rolling_days', value: userData.f1_rolling_days + 1 },
        { key: 'f1_consecutive_days', value: userData.f1_consecutive_days + 1 },
        {
          key: 'f1_next_morning_session',
          value: addDays(1, userData.last_active).toISOString(),
        },
        {
          key: 'f2_unlock_date',
          value: didUserUnlockF2
            ? addDays(0, userData.last_active).toISOString()
            : userData.f2_unlock_date,
        },
        {
          key: 'f3_unlock_date',
          value: didUserUnlockF3
            ? addDays(0, userData.last_active).toISOString()
            : userData.f3_unlock_date,
        },
      ];

      await updateUserDocument(userData.created_by, update);

      await createActivity({
        uid: userData.created_by,
        config: {
          name: F1ActivityNames.RECORD,
          details: { recordingId },
        },
      });

      return { isFirstDay, didUserUnlockF2, didUserUnlockF3 };
    },
    [updateUserDocument, userData, isDemo],
  );

  const onWellnessFinish = React.useCallback(
    async (values: WellnessCheckValues) => {
      if (typeof userData === 'undefined') {
        throw new Error('User Data is not defined. Please login.');
      }

      const tmapi = new TMApi();
      await tmapi.addWellness(values);

      // update to user's document
      const update: UserDataUpdate = [
        {
          key: 'last_wellness_check',
          value: userData.last_active,
        },
        {
          key: 'f1_status',
          value: 'FLOW_START',
        },
      ];

      await updateUserDocument(userData.created_by, update);

      await createActivity({
        uid: userData.created_by,
        config: {
          name: F1ActivityNames.WELLNESS,
          details: {
            wellnessId: 'wellnessId', // TODO(ts): this is a dummy, because I'm not sure we need this
            values,
          },
        },
      });
    },
    [updateUserDocument, userData],
  );

  const onMeditationListen = React.useCallback(
    async (meditationId: string) => {
      if (typeof userData === 'undefined') {
        throw new Error('User Data is not defined. Please login.');
      }

      const update: UserDataUpdate = [
        { key: 'f2_last_listen', value: userData.last_active },
      ];

      await updateUserDocument(userData.created_by, update);

      return createActivity({
        uid: userData.created_by,
        config: {
          name: F2ActivityNames.LISTEN_MEDITATION,
          details: { meditationId },
        },
      });
    },
    [updateUserDocument, userData],
  );

  const onMeditationCreation = React.useCallback(
    async (meditationId: string) => {
      if (typeof userData === 'undefined') {
        throw new Error('User Data is not defined. Please login.');
      }

      const update: UserDataUpdate = [
        { key: 'f2_last_creation', value: userData.last_active },
      ];

      await updateUserDocument(userData.created_by, update);

      return createActivity({
        uid: userData.created_by,
        config: {
          name: F2ActivityNames.CREATE_MEDITATION,
          details: { meditationId },
        },
      });
    },
    [updateUserDocument, userData],
  );

  const onCommunityWatering = React.useCallback(async () => {
    if (typeof userData === 'undefined') {
      throw new Error('User Data is not defined. Please login.');
    }

    const update: UserDataUpdate = [
      {
        key: 'f3_last_watering',
        value: userData.last_active,
      },
      {
        key: 'f3_water_level',
        value: userData.f3_water_level >= 3 ? 0 : userData.f3_water_level + 1,
      },
    ];

    const newUserData = await updateUserDocument(userData.created_by, update);

    createActivity({
      uid: userData.created_by,
      config: {
        name: F3ActivityNames.COMMUNITY_WATERING,
        details: {
          currentLevel: userData.f3_water_level,
        },
      },
    });

    return newUserData.f3_water_level;
  }, [updateUserDocument, userData]);

  return [
    state,
    {
      loginAsUser,
      toggleFunctionalityNotifications,
      updateUserDocument,
      onOnboardFinish,
      onListenFinish,
      onRecordFinish,
      onWellnessFinish,
      onMeditationListen,
      onMeditationCreation,
      onCommunityWatering,
    },
  ] as const;
}

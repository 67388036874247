import { InsightAppearances } from '@core/insights';
import { css } from '@emotion/react';
import { SvgCalendar, SvgSpiral } from '@web/components/svgs/insights-icons';
import { useInsightsData } from '@web/features/insights/use-insights-data';
import { theme } from '@web/features/theme';

export const insightAppearances: Array<{
  key: InsightAppearances;
  icon: JSX.Element;
}> = [
  { key: 'calendar', icon: <SvgCalendar /> },
  { key: 'spiral', icon: <SvgSpiral /> },
];

/**
 * # AppearanceNav
 *
 */

export function AppearanceNav({
  appearance,
  setAppearance,
}: {
  appearance: ReturnType<typeof useInsightsData>['0']['appearance'];
  setAppearance: ReturnType<typeof useInsightsData>['1']['setAppearance'];
}) {
  const isActive = (key: string) => key === appearance;

  return (
    <nav css={styles.wrapper}>
      {insightAppearances.map(({ key, icon }) => (
        <button
          key={key}
          type="button"
          onClick={() => setAppearance(key)}
          css={styles.navLink}
          className={isActive(key) ? 'active' : 'inactive'}
        >
          {icon}
        </button>
      ))}
    </nav>
  );
}

const styles = {
  wrapper: css`
    flex: 0.5 1 10%;
    display: flex;
  `,
  navLink: css`
    height: ${theme.space[12]};
    width: ${theme.space[12]};
    padding: ${theme.space[2]};

    cursor: pointer;
    background: none;
    border: none;
    border-radius: ${theme.radii.md};

    svg {
      height: 100%;
      width: 100%;
      fill: ${theme.colors.whiteAlpha[900]};
    }

    &.active {
      background: ${theme.colors.whiteAlpha[900]};

      svg {
        fill: ${theme.colors.blue[300]};
      }
    }
  `,
};

import { createMetadata } from '@core/metadata';
import { Recording } from '@core/recordings';
import { UserData } from '@core/users/users';
import { ClientRecording } from '@web/components/recorder/recorder';
import { TMApi } from '@web/services/tmapi';

/**
 * # UploadRecording
 * @packageDescription
 *
 * Upload recording to firebase storage and store a reference in firestore
 *
 * It's important that files uploaded (from ios devices) are not 'converted' to mp3
 *
 * Recording from the browser must be encoded as mp3 in order to work
 *
 *
 * @param uid user's uid from firestore collection `users`
 * @param recording recording from the device's recording or upload
 *
 * @note We are using mocked recording in emulated environment because firebase
 *  storage is not supported
 */
export async function uploadRecording(
  userData: UserData,
  recording: ClientRecording,
  name: string | null,
) {
  const pendingRecording: Recording = {
    ...createMetadata({
      uid: userData.created_by,
      lastActive: userData.last_active,
    }),
    type: 'PRIVATE',
    status: 'PENDING',
    error: null,
    recording_metadata: null,
    recording_output: null,
    name,
  };

  try {
    let tmapi = new TMApi();
    let uploadURL = tmapi.createUploadTicket("recordings");

    // download the blob and coerce into a file with a name and type
    const file = await fetch(recording.blobUrl)
      .then((res) => res.blob())
      .then(
        (blobFile) =>
          new File([blobFile], "recording", { type: recording.mimeType }),
      );

    let recordingId = await tmapi.uploadRecording(await uploadURL, file, { type: 'PRIVATE' })

    /** recording document to be saved */
    const userRecording = {
      ...pendingRecording,
      status: 'READY',
      recording_metadata: null,
    };

    return { recordingId, userRecording };
  } catch (error) {
    throw error;
  }
}

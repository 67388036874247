import { InsightAppearances, InsightTypes, Timeframes } from '@core/insights';
import { getStringOrFirstInArray } from '@web/utils/get-string-or-first-in-array';
import * as dateFns from 'date-fns';
import { ParsedUrlQuery } from 'querystring';
import { insightAppearances } from './appearance-nav';
import { timeframes } from './timeframe-nav';
import { insightTypes } from './type-nav';

/**
 * # createInsightsUrl
 */
export function createInsightsUrl({
  timeframe = timeframes[0].key,
  type = insightTypes[0].key,
  appearance = insightAppearances[0].key,
  dateStr,
}: {
  timeframe?: Timeframes;
  type?: InsightTypes;
  dateStr?: string | null;
  appearance?: InsightAppearances;
}) {
  const baseUrl = '/insights';
  const date = dateStr
    ? new Date(dateStr).toISOString()
    : new Date().toISOString();

  return `${baseUrl}?timeframe=${timeframe}&type=${type}&appearance=${appearance}&date=${date}`;
}

/**
 * # parseInsightsUrl
 */
export function parseInsightUrl(query: ParsedUrlQuery) {
  const { timeframe, type, date, appearance } = query;

  const timeframeStr =
    typeof timeframe !== 'undefined'
      ? (getStringOrFirstInArray(timeframe) as Timeframes)
      : timeframes[0].key;

  const typeStr =
    typeof type !== 'undefined'
      ? (getStringOrFirstInArray(type) as InsightTypes)
      : insightTypes[0].key;

  const appearanceStr =
    typeof appearance !== 'undefined'
      ? (getStringOrFirstInArray(appearance) as InsightAppearances)
      : insightAppearances[0].key;

  const dateStr =
    typeof date !== 'undefined'
      ? getStringOrFirstInArray(date)
      : new Date().toISOString();

  const dateObj = new Date(dateStr);

  return {
    dateObj,
    type: typeStr,
    timeframe: timeframeStr,
    appearance: appearanceStr,
    date: dateFns.formatISO(dateObj),
    week: dateFns.getWeek(dateObj) + 1,
    month: dateFns.getMonth(dateObj) + 1,
    year: dateFns.getYear(dateObj),
  };
}

import * as React from 'react';

export function SvgMic(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16.004" {...props}>
      <path d="M14.667 8.535a6.063 6.063 0 01-6 6.111H7.333a6.063 6.063 0 01-6-6.111H0a7.411 7.411 0 007.333 7.468h1.334A7.411 7.411 0 0016 8.535z" />
      <path d="M6.027 8.831H3.442A4.655 4.655 0 008 12.906a4.655 4.655 0 004.559-4.077H9.974V7.473h2.631V5.436H9.974V4.078h2.585A4.655 4.655 0 008 0a4.655 4.655 0 00-4.558 4.078h2.585v1.358H3.395v2.037h2.632z" />
    </svg>
  );
}

export function SvgRecord(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
      <path d="M68.333 51.838a16.517 16.517 0 01-16.5 16.5h-3.666a16.519 16.519 0 01-16.5-16.5H28a20.189 20.189 0 0020.167 20.165h3.667A20.189 20.189 0 0072 51.838z" />
      <path d="M44.5 51.838h-7.203a12.834 12.834 0 0025.406 0h-7.2v-3.666h7.333v-5.5H55.5v-3.669h7.2a12.834 12.834 0 00-25.403 0h7.2v3.67h-7.33v5.5H44.5z" />
    </svg>
  );
}

export function SvgPlay(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13.29 16.32"
      {...props}
    >
      <path d="M11.9 5.414L5.41.657A3.4 3.4 0 000 3.398v9.519a3.4 3.4 0 005.41 2.741l6.49-4.757a3.4 3.4 0 000-5.484z" />
    </svg>
  );
}

export function SvgPause(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.658 43.544"
      {...props}
    >
      <g transform="translate(-3)">
        <path
          d="M9.35,0A6.35,6.35,0,0,0,3,6.35V37.194a6.35,6.35,0,1,0,12.7,0V6.35A6.35,6.35,0,0,0,9.35,0Z"
          transform="translate(0)"
          fill="#cfaefe"
        />
        <path
          d="M20.35,0A6.35,6.35,0,0,0,14,6.35V37.194a6.35,6.35,0,1,0,12.7,0V6.35A6.35,6.35,0,0,0,20.35,0Z"
          transform="translate(8.958)"
          fill="#cfaefe"
        />
      </g>
    </svg>
  );
}

export function SvgMenuVertical(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g transform="translate(17 6) rotate(90)">
        <rect
          width="24"
          height="24"
          rx="5"
          transform="translate(-6 -7)"
          fill="#fff"
        />
        <g transform="translate(-0.6 3.2)">
          <circle
            cx="2"
            cy="2"
            r="2"
            transform="translate(-1.4 -0.2)"
            fill="#4a316c"
          />
          <circle
            cx="2"
            cy="2"
            r="2"
            transform="translate(4.6 -0.2)"
            fill="#4a316c"
          />
          <circle
            cx="2"
            cy="2"
            r="2"
            transform="translate(10.6 -0.2)"
            fill="#4a316c"
          />
        </g>
      </g>
    </svg>
  );
}

export function SvgRedo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44.605 44.605"
      {...props}
    >
      <g id="Border" fill="none" stroke="#fff" strokeWidth="3">
        <circle cx="22.302" cy="22.302" r="22.302" stroke="none" />
        <circle cx="22.302" cy="22.302" r="20.802" fill="none" />
      </g>

      <path
        d="M-2290.852-2505a16.539,16.539,0,0,1-11.772-4.876,16.539,16.539,0,0,1-4.876-11.772,16.539,16.539,0,0,1,4.876-11.772,16.539,16.539,0,0,1,11.772-4.876,16.538,16.538,0,0,1,11.772,4.876,16.539,16.539,0,0,1,4.876,11.772,16.539,16.539,0,0,1-4.876,11.772A16.538,16.538,0,0,1-2290.852-2505Zm-5.671-10.819,0,0a8.107,8.107,0,0,0,5.719,2.341,8.136,8.136,0,0,0,2.809-.5,8.148,8.148,0,0,0,3.66-2.691,8.144,8.144,0,0,0,1.651-4.231.682.682,0,0,0-.176-.525.688.688,0,0,0-.5-.226h-.007a.67.67,0,0,0-.667.6,6.818,6.818,0,0,1-4.479,5.814,6.78,6.78,0,0,1-2.291.4,6.84,6.84,0,0,1-4.862-2.045h1.868a.682.682,0,0,0,.681-.68.683.683,0,0,0-.681-.682h-2.723a1.364,1.364,0,0,0-1.362,1.363v2.724a.681.681,0,0,0,.68.68.682.682,0,0,0,.681-.68v-1.66Zm5.622-14a8.122,8.122,0,0,0-2.808.5,8.15,8.15,0,0,0-3.66,2.692,8.14,8.14,0,0,0-1.652,4.231.686.686,0,0,0,.176.525.683.683,0,0,0,.506.224h.008a.666.666,0,0,0,.664-.6,6.821,6.821,0,0,1,4.48-5.814,6.783,6.783,0,0,1,2.291-.4,6.839,6.839,0,0,1,4.862,2.045h-1.869a.681.681,0,0,0-.68.68.681.681,0,0,0,.68.68h2.723a1.363,1.363,0,0,0,1.362-1.361v-2.724a.682.682,0,0,0-.681-.68.681.681,0,0,0-.68.68v1.66a8.109,8.109,0,0,0-5.721-2.343Z"
        transform="translate(2313.154 2543.948)"
        fill="#d35555"
      />
    </svg>
  );
}

export function SvgConfirm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44.605 44.605"
      {...props}
    >
      <g id="Border" fill="none" stroke="#fff" strokeWidth="3">
        <circle cx="22.302" cy="22.302" r="22.302" stroke="none" />
        <circle cx="22.302" cy="22.302" r="20.802" fill="none" />
      </g>

      <path
        d="M-1942.747-1456.894a16.539,16.539,0,0,1-11.772-4.876,16.539,16.539,0,0,1-4.876-11.772,16.539,16.539,0,0,1,4.876-11.772,16.54,16.54,0,0,1,11.772-4.876,16.54,16.54,0,0,1,11.772,4.876,16.539,16.539,0,0,1,4.876,11.772,16.539,16.539,0,0,1-4.876,11.772A16.539,16.539,0,0,1-1942.747-1456.894Zm-7.173-18.525a1.268,1.268,0,0,0-.922.4,1.413,1.413,0,0,0,0,1.931l5.993,6.275,10.318-10.8a1.413,1.413,0,0,0,0-1.931,1.268,1.268,0,0,0-.922-.4,1.268,1.268,0,0,0-.922.4l-8.474,8.873-4.148-4.344A1.267,1.267,0,0,0-1949.92-1475.419Z"
        transform="translate(1965.05 1495.845)"
        fill="#8fd076"
      />
    </svg>
  );
}

import { InsightState, Timeframes } from '@core/insights';
import { css } from '@emotion/react';
import { nav, navAnimation, navLink } from '@web/components/menu/menu.styles';
import { theme } from '@web/features/theme';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import { createInsightsUrl } from './insights-url';

export const timeframes: Array<{ key: Timeframes; title: string }> = [
  { key: 'overview', title: 'Insights Overview' },
  { key: 'daily', title: 'Daily Insights' },
  { key: 'weekly', title: 'Weekly Insights' },
  { key: 'monthly', title: 'Monthly Insights' },
];

/**
 * # Timeframe Navigation
 * @packageDescription
 */
export function TimeframeNav({ date, type }: InsightState) {
  const router = useRouter();

  const [state, setState] = React.useState<{
    status: 'open' | 'closed';
  }>({
    status: 'closed',
  });

  React.useEffect(() => {
    const toggle = (toggleValue?: boolean) => {
      let newToggleStatus: 'open' | 'closed';

      if (toggleValue) {
        newToggleStatus = toggleValue ? 'open' : 'closed';
      } else {
        newToggleStatus = state.status === 'open' ? 'closed' : 'open';
      }

      setState((prev) => ({ ...prev, status: newToggleStatus }));
    };

    if (state.status === 'open') {
      toggle(false);
    }
  }, [router.query.timeframe, state.status]);

  return (
    <div css={styles.wrapper}>
      {/* <button onClick={() => toggle()} css={styles.toggle}>
        {state.status === 'closed' ? <MdArrowDropDown /> : <MdArrowDropUp />}
      </button> */}

      <motion.nav
        css={styles.nav}
        className={state.status}
        variants={navAnimation}
        initial="initial"
        animate={state.status === 'open' ? 'animate' : 'initial'}
        exit="exit"
      >
        {timeframes.map((timeframe) => (
          <Link
            key={timeframe.key}
            href={createInsightsUrl({
              timeframe: timeframe.key,
              type,
              dateStr: date,
            })}
            passHref
            css={styles.navLink}>
            {timeframe.key}
          </Link>
        ))}
      </motion.nav>
    </div>
  );
}

const styles = {
  wrapper: css`
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    z-index: ${theme.zIndices.dropdown};
  `,
  title: css`
    text-align: center;
    font-size: clamp(${theme.fontSizes.md}, 5vw, ${theme.fontSizes['4xl']});
    font-weight: ${theme.fontWeights.semibold};
    font-family: ${theme.fonts.heading};
  `,
  toggle: css`
    display: flex;
    cursor: pointer;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: transparent;
    border: none;
    color: ${theme.colors.info};
    font-size: ${theme.fontSizes['3xl']};

    svg {
      height: 100%;
      width: 100%;
    }
  `,
  nav,
  navLink,
};

import * as React from 'react';

export function SvgBackButton(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 40" {...props}>
      <path d="M15.462 13.293l-4.587 4.586a3.007 3.007 0 000 4.242l4.586 4.586a1 1 0 101.414-1.414L12.584 21H33a1 1 0 000-2H12.583l4.293-4.293a1 1 0 10-1.414-1.414z" />
    </svg>
  );
}

export function SvgF1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.61 30.61"
      {...props}
    >
      <g>
        <circle
          cx={0.99}
          cy={0.99}
          r={0.99}
          transform="translate(14.322 14.322)"
        />
        <path d="M3.64 17.688a11.786 11.786 0 001.734 4.182.85.85 0 01-.108 1.071L3.28 24.927l2.405 2.405 1.986-1.986a.85.85 0 011.071-.108 11.786 11.786 0 004.182 1.734.85.85 0 01.681.834v2.806h3.4v-2.805a.85.85 0 01.681-.833 11.786 11.786 0 004.182-1.734.85.85 0 011.071.108l1.986 1.986 2.405-2.405-1.986-1.986a.85.85 0 01-.108-1.071 11.79 11.79 0 001.734-4.182.85.85 0 01.834-.681h2.806v-3.4h-2.806a.85.85 0 01-.833-.681 11.79 11.79 0 00-1.734-4.182.85.85 0 01.108-1.071l1.986-1.986-2.405-2.405L22.94 5.27a.85.85 0 01-1.071.108 11.786 11.786 0 00-4.182-1.734.85.85 0 01-.681-.834V0h-3.4v2.806a.85.85 0 01-.681.833 11.786 11.786 0 00-4.182 1.734.85.85 0 01-1.071-.108L5.686 3.28 3.281 5.685l1.986 1.986a.85.85 0 01.108 1.071 11.786 11.786 0 00-1.734 4.182.85.85 0 01-.834.681H0v3.4h2.806a.85.85 0 01.834.681zM15.307 5.1a10.2 10.2 0 11-10.2 10.2 10.2 10.2 0 0110.2-10.2z" />
        <path d="M15.303 23.81a8.5 8.5 0 10-8.5-8.5 8.5 8.5 0 008.5 8.5zm-.85-10.908v-2.699a.85.85 0 111.7 0v2.7a2.563 2.563 0 011.554 1.554h3.548a.85.85 0 010 1.7h-3.548a2.551 2.551 0 11-3.255-3.255z" />
      </g>
    </svg>
  );
}

export function SvgF2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25.36 29.88"
      {...props}
    >
      <g>
        <path d="M17.495 4.718A4.718 4.718 0 1112.777 0a4.718 4.718 0 014.718 4.718zm0 0" />
        <path d="M22.213 23.589h-.83c-5.46 0-11.751 6.29-11.751 6.29h12.581a3.145 3.145 0 100-6.29zm0 0" />
        <path d="M4.661 13.727l-.754 8.29h.265a7.916 7.916 0 012.2.309 20.013 20.013 0 016.31 3.039c2.44-1.68 5.636-3.349 8.7-3.349h.265l-.753-8.29a4.7 4.7 0 00-4.7-4.29H9.359a4.7 4.7 0 00-4.698 4.291zm0 0" />
        <path d="M3.98 23.589h-.83a3.145 3.145 0 100 6.29h4.263l1.107-1.117a30.967 30.967 0 012.808-2.419c-2.167-1.429-4.848-2.754-7.348-2.754zm0 0" />
      </g>
    </svg>
  );
}

export function SvgF3(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448.26 448.26"
      {...props}
    >
      <path d="M221.2 164.587c4.217 1.654 8.354-2.394 6.956-6.701-9.594-29.565-44.331-40.594-76.623-14.364-16.986 13.799-23.369 16.731-23.53 16.805 11.198 11.196 23.991 48.766 51.91 52.921 30.755 5.576 57.975-24.682 35.705-38.528-14.646-9.105-31.89-14.394-50.374-14.394 19.378-4.368 38.723-2.495 55.956 4.261zm139.264-21.067c-55.742-45.278-91.291 26.126-69.194 19.048 15.694-5.027 32.851-6.122 50.061-2.243-29.159 0-56.038 13.363-73.959 35.114 1.472-34.182 3.698-55.578 6.279-71.494 14.895-.512 34.747-12.54 45.649-33.587 18.795-33.636 10.079-68.124 7.274-89.886-14.686 14.301-78.839-2.456-104.271 41.124-11.234 19.251-7.969 42.677 3.849 59.82 3.741 5.427 12.686 2.823 12.941-3.763 1.11-28.65 15.369-50.296 41.951-62.285-11.928 15.455-19.76 32.758-23.806 50.642-6.289 27.859-12.858 46.134-15.27 134.929-.245 9.005 7.05 16.459 16.058 16.346 6.521-.082 12.107-4.22 14.615-10.24 3.814-9.156 10.022-18.632 20.124-27.314 9.6 10.611 24.208 16.257 39.323 13.516 27.8-4.137 40.774-41.789 51.909-52.923-.163-.072-6.547-3.004-23.533-16.804zm81.942 110.638c-17.426-11.823-36.392-3.398-45.102 1.631l-109.304 80-64 .014-16-16h58.776c17.677 0 37.474-11.514 37.474-32.007 0-21.757-19.797-32.007-37.474-32.007H244.75c-16.362 0-35.483 1.459-48.965-5.758-12.809-6.518-27.559-10.227-43.304-10.227-26.439 0-50.102 10.54-66.317 27.162L0 351.789l96 96 32-48h151.216a65.875 65.875 0 0043.75-16.626L443.5 276.088c6.701-5.952 6.323-16.897-1.094-21.93z" />
    </svg>
  );
}

export function SvgInsights(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M76 240c12.102 0 23.055-4.855 31.148-12.652l44.403 22.199c-.223 1.808-.551 3.586-.551 5.453 0 24.813 20.188 45 45 45s45-20.188 45-45c0-6.926-1.703-13.41-4.512-19.277l60.235-60.235C302.59 178.297 309.074 180 316 180c24.813 0 45-20.188 45-45 0-4.672-.918-9.09-2.246-13.328L411.09 82.43C418.23 87.2 426.79 90 436 90c24.813 0 45-20.188 45-45S460.812 0 436 0s-45 20.188-45 45c0 4.672.918 9.09 2.246 13.328L340.91 97.57C333.77 92.8 325.21 90 316 90c-24.813 0-45 20.188-45 45 0 6.926 1.703 13.41 4.512 19.277l-60.235 60.235C209.41 211.703 202.926 210 196 210c-12.102 0-23.055 4.855-31.148 12.652l-44.403-22.199c.223-1.808.551-3.586.551-5.453 0-24.813-20.187-45-45-45s-45 20.188-45 45 20.188 45 45 45zm0 0" />
      <path d="M497 482h-16V165c0-8.29-6.71-15-15-15h-60c-8.29 0-15 6.71-15 15v317h-30V255c0-8.29-6.71-15-15-15h-60c-8.29 0-15 6.71-15 15v227h-30V375c0-8.29-6.71-15-15-15h-60c-8.29 0-15 6.71-15 15v107h-30V315c0-8.29-6.71-15-15-15H46c-8.29 0-15 6.71-15 15v167H15c-8.29 0-15 6.71-15 15s6.71 15 15 15h482c8.29 0 15-6.71 15-15s-6.71-15-15-15zm0 0" />
    </svg>
  );
}

import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { FaSms, FaTwitterSquare } from 'react-icons/fa';
import { MdShare } from 'react-icons/md';

import * as menuStyles from '@web/components/menu/menu.styles';
import { SvgMenuVertical } from '@web/components/svgs/recorder-icons';
import { colors, fadeInDown, theme, variantProps } from '@web/features/theme';
import { TILTSwal } from '@web/services/swal';

export function ShareModal() {
  return (
    <>
      <motion.button
        aria-label="open share menu"
        onClick={shareSwal}
        css={css`
          ${menuStyles.menuButton}
          position: absolute;
          top: 0;
          right: 0;

          svg {
            height: ${theme.space[6]};
            width: ${theme.space[6]};
          }
        `}
        variants={fadeInDown}
        {...variantProps}
      >
        <MdShare />
      </motion.button>
    </>
  );
}
export function shareSwal() {
  const canShare = typeof window !== 'undefined' && navigator.share;
  const shareUrl = new URL(window.location.href);
  const shareOrigin = shareUrl.origin;
  const twitterUrl = new URL('https://twitter.com/intent/tweet');
  const smsUrl = new URL('sms:');

  const data: ShareData = {
    // we only want the root url, not the whole path
    url: shareOrigin,
    title: 'Time Machine',
    text: 'Learn to use unconditional love to connect with yourself.',
    files: [],
  };

  if (data.url && data.title) {
    twitterUrl.searchParams.set('text', data.title);
    twitterUrl.searchParams.set('url', data.url);

    smsUrl.searchParams.set('body', `${data.title} ${data.url}`);
  } else if (data.url) {
    twitterUrl.searchParams.set('url', data.url);
    smsUrl.searchParams.set('body', data.url);
  } else {
    throw new Error('Unable to share without a url');
  }

  const nativeShare = async () => {
    if (!canShare) return;

    const createOGData = async () => {
      const ogImage = await fetch(`${shareOrigin}/og-image.png`);
      const ogImageBlob = await ogImage.blob();
      const metadata = {
        type: 'image/png',
      };

      const file = new File([ogImageBlob], 'og-image.png', metadata);
      return file;
    };

    const ogData = await createOGData();
    const shareData = {
      ...data,
      files: [ogData].concat(data.files || []),
    };

    await window.navigator.share(shareData).catch((_) => {});
  };

  TILTSwal.fire({
    title: 'Share Time Machine',
    confirmButtonText: 'Copy Link',
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    html: (
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          & > a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            span {
              margin-top: ${theme.space[2]};
              color: ${theme.colors.blackAlpha[900]};
            }

            svg {
              height: ${theme.space[16]};
              width: ${theme.space[16]};
            }

            &:not(:last-of-type) {
              margin-right: ${theme.space[2]};
            }
          }
        `}
      >
        <a href={smsUrl.toString()}>
          <FaSms color={colors.success} />
          <span>SMS</span>
        </a>

        <a href={twitterUrl.toJSON()}>
          <FaTwitterSquare color={colors.twitter[500]} />
          <span>Twitter</span>
        </a>

        {canShare ? (
          <a href="#" onClick={nativeShare}>
            <SvgMenuVertical
              css={css`
                rect {
                  fill: ${colors.blackAlpha[100]};
                }

                circle {
                  fill: ${colors.blackAlpha[600]};
                }
              `}
            />
            <span>More</span>
          </a>
        ) : null}
      </div>
    ),
    showLoaderOnConfirm: true,
    preConfirm: () =>
      navigator.clipboard.writeText(shareOrigin).then(() => {
        TILTSwal.fire({
          title: 'Copied!',
          text: 'Link copied to clipboard',
          icon: 'success',
          timer: 1500,
        });
      }),
  });
}

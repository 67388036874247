import { buttonAnimation, variantProps } from '@web/features/theme';
import { HTMLMotionProps, motion } from 'framer-motion';
import * as styles from './button.styles';

/**
 * # Button
 *
 * Simple button components
 *
 * @export
 * @param {(HTMLMotionProps<'button'> & { style?: { [key: string]: string } })} {
 *   children,
 *   type,
 *   ...rest
 * }
 * @return {JSX.Element}
 */
export function Button({
  children,
  ...rest
}: HTMLMotionProps<'button'> & { style?: { [key: string]: string } }) {
  return (
    <motion.button
      {...buttonAnimation}
      {...variantProps}
      aria-label={typeof children === 'string' ? children : undefined}
      tabIndex={0}
      css={styles.defaultButton}
      {...rest}
    >
      {children}
    </motion.button>
  );
}

import * as React from 'react';

export function SvgCalendar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M0 19a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5v-9H0zm17-4.5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-5 0a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-5 0A1.5 1.5 0 115.5 16 1.5 1.5 0 017 14.5zM19 2h-1V1a1 1 0 10-2 0v1H8V1a1 1 0 10-2 0v1H5a5.006 5.006 0 00-5 5v1h24V7a5.006 5.006 0 00-5-5z" />
    </svg>
  );
}

export function SvgSpiral(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 23.837" {...props}>
      <path d="M17.344 7.079a9.289 9.289 0 00-6.412-3.98 10.593 10.593 0 00-7.769 1.986A9.576 9.576 0 00.018 9.004a.179.179 0 00.309.176c2.08-2.918 4.75-4.156 8-3.764a.18.18 0 01.036.349 9.643 9.643 0 00-6.027 5.649 9.674 9.674 0 002.411 11.428.181.181 0 00.273-.234 9.067 9.067 0 01-1.076-8.712.18.18 0 01.349.043c.443 4.494 2.436 7.1 5.29 8.636a9.659 9.659 0 008.809.381.178.178 0 00-.108-.338 7.991 7.991 0 01-7.528-2.087.179.179 0 01.173-.3 9.872 9.872 0 0010.068-2.378 9.511 9.511 0 002.764-9.244.18.18 0 00-.356.04c.04 3.692-1.421 6.2-4.275 7.6a.18.18 0 01-.227-.266c1.839-2.558 2.638-5.6 1.1-9.723 0-.007 0-.014-.007-.018a9.834 9.834 0 00-8.413-6.243.181.181 0 00-.079.349c3.314 1.22 5.621 3.22 6.168 6.6a.18.18 0 01-.328.131zm-5.387 9.014a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z" />
    </svg>
  );
}

export function SvgDove(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.505 16" {...props}>
      <path d="M10.356 16H2.65l4.52-5.752a4.677 4.677 0 01-3.61-2.564l-.414-.827h.4A4.725 4.725 0 012.417 5.4L2 4.571h.4a4.725 4.725 0 01-1.129-1.459L.86 2.286h.4A4.048 4.048 0 01.413.827L0 0h6.356a6.32 6.32 0 015.809 3.89l2.192-2.79A2.879 2.879 0 0119.5 2.852l.006.576h-.577a1.717 1.717 0 00-1.714 1.715v2.286a6.293 6.293 0 01-6.286 6.286.572.572 0 00-.571.571z" />
    </svg>
  );
}

export function SvgStrength(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.187 16" {...props}>
      <path d="M8.093 16h3.069C10.6 14.047 12 9.583 12 9.583c4.426-3.318 4.185-4.743 4.185-4.743S14.222.466 13.115.097 11.8.482 11.8 1.22s1.316.272 1.316.272a4.487 4.487 0 01.279 1.4 4.72 4.72 0 00.558 2.511A2.334 2.334 0 0012 7.072a3.107 3.107 0 00-2.232.837c-1.106-1.106-.753-1.365-.279-2.79.558-1.674-.558-2.511-1.4-2.511S6.14 3.445 6.7 5.119c.474 1.425.827 1.684-.279 2.79a3.107 3.107 0 00-2.232-.837 2.334 2.334 0 00-1.955-1.674 4.72 4.72 0 00.558-2.511 4.487 4.487 0 01.279-1.4s1.316.465 1.316-.272S4.178-.267 3.071.097 0 4.84 0 4.84s-.239 1.425 4.187 4.743c0 0 1.4 4.464.837 6.417z" />
    </svg>
  );
}

export function SvgSunny(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <circle cx="5.12" cy="5.12" r="5.12" transform="translate(2.88 2.88)" />
      <path d="M8 2.16a.36.36 0 00.36-.36V.36a.36.36 0 10-.72 0V1.8a.36.36 0 00.36.36zM8 13.84a.36.36 0 00-.36.36v1.44a.36.36 0 10.72 0V14.2a.36.36 0 00-.36-.36zM15.64 7.64H14.2a.36.36 0 000 .72h1.44a.36.36 0 100-.72zM2.16 8a.36.36 0 00-.36-.36H.36a.36.36 0 100 .72H1.8A.36.36 0 002.16 8zM4.581 2.848a.36.36 0 00.313.18.355.355 0 00.18-.048.36.36 0 00.132-.492l-.72-1.247a.36.36 0 00-.624.36zM11.418 13.152a.36.36 0 00-.624.36l.72 1.247a.36.36 0 00.313.18.355.355 0 00.18-.048.36.36 0 00.132-.492zM13.335 5.254a.355.355 0 00.18-.048l1.247-.72a.36.36 0 00-.334-.638l-.026.015-1.247.72a.36.36 0 00.18.672zM2.488 10.795l-1.247.72a.36.36 0 00.18.672.355.355 0 00.18-.048l1.247-.72a.36.36 0 00-.334-.638l-.026.015zM1.242 4.486l1.247.72a.355.355 0 00.179.048.36.36 0 00.18-.672l-1.247-.72a.36.36 0 10-.386.608l.026.015zM14.758 11.515l-1.247-.72a.36.36 0 10-.386.608l.026.015 1.247.72a.356.356 0 00.18.048.36.36 0 00.18-.672zM10.926 2.98a.355.355 0 00.18.048.36.36 0 00.313-.18l.72-1.247a.36.36 0 00-.624-.36l-.72 1.247a.36.36 0 00.131.492zM5.073 13.02a.36.36 0 00-.492.132l-.72 1.247a.36.36 0 00.132.492.355.355 0 00.18.048.36.36 0 00.313-.18l.72-1.247a.36.36 0 00-.132-.492z" />
    </svg>
  );
}

export function SvgYoga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.993 16" {...props}>
      <path d="M13.956 12.491a.868.868 0 00-.568-1.089c-2.042-.642-2.909-4.3-3.113-5.648a1.378 1.378 0 00-.128-.376c-.375-.714-1.795-1.047-1.954-1.066a.356.356 0 01-.336-.329 2.521 2.521 0 00.84-1.951C8.694.91 8.575 0 7.045 0h-.12c-1.53 0-1.648.91-1.648 2.033a2.521 2.521 0 00.84 1.951.364.364 0 01-.336.329c-.172.019-1.8.4-2.021 1.234 0 0-.027.119-.043.211a13.765 13.765 0 01-.676 2.629c-.431 1.162-1.2 2.628-2.434 3.015a.868.868 0 00.251 1.7 1.493 1.493 0 00-.839 1.312 1.6 1.6 0 00.348.876 2.085 2.085 0 001.746.71 4.344 4.344 0 00.461-.026c.651-.07 1.307-.175 1.941-.277.23-.037.46-.074.691-.109a14.476 14.476 0 011.65-.088h.269a14.489 14.489 0 011.644.093c.23.035.461.072.691.109.634.1 1.291.207 1.941.277a4.345 4.345 0 00.455.021 2.085 2.085 0 001.746-.713 1.6 1.6 0 00.348-.876 1.493 1.493 0 00-.839-1.312h.012a.869.869 0 00.833-.608zm-10.146-.11a4.357 4.357 0 01-.918.2c-.123.017-.273.039-.473.07s-.375.049-.54.082a6.572 6.572 0 002.723-3.56 10.135 10.135 0 01.054 1.127 2.389 2.389 0 01-.849 2.081zm7.746.274c-.2-.031-.35-.054-.473-.07-1.187-.146-1.771-.944-1.771-2.284a10.117 10.117 0 01.066-1.167 6.594 6.594 0 002.752 3.611 5.726 5.726 0 00-.574-.09z" />
    </svg>
  );
}

export function SvgSunAndClouds(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 257 193"
      {...props}
    >
      <path
        fill="#FFC107"
        d="M161.199 157.285c34.289 0 62.086-27.797 62.086-62.086 0-34.29-27.797-62.086-62.086-62.086S99.113 60.91 99.113 95.199s27.797 62.086 62.086 62.086z"
      />
      <path
        fill="#FFD54F"
        d="M161.199 24.835a4.139 4.139 0 004.139-4.139V4.139a4.14 4.14 0 10-8.278 0V20.7a4.139 4.139 0 004.139 4.135zM161.199 165.564a4.14 4.14 0 00-4.139 4.139v16.556a4.14 4.14 0 108.278 0v-16.556a4.14 4.14 0 00-4.139-4.139zM252.26 91.06h-16.557a4.14 4.14 0 00-2.927 7.066 4.14 4.14 0 002.927 1.212h16.557a4.139 4.139 0 100-8.278zM90.835 95.199a4.14 4.14 0 00-4.139-4.139H70.139a4.14 4.14 0 000 8.278H86.7a4.14 4.14 0 004.135-4.139zM120.363 32.748a4.138 4.138 0 003.593 2.069 4.137 4.137 0 003.584-6.209l-8.277-14.337a4.14 4.14 0 00-7.169 4.139l8.269 14.338zM202.035 157.65a4.137 4.137 0 00-7.582.998 4.135 4.135 0 00.413 3.141l8.278 14.338a4.14 4.14 0 003.593 2.069 4.143 4.143 0 004.103-4.679 4.135 4.135 0 00-.519-1.529l-8.286-14.338zM225.719 62.087a4.08 4.08 0 002.069-.555l14.338-8.278a4.137 4.137 0 001.739-5.58 4.139 4.139 0 00-5.576-1.755 3.817 3.817 0 00-.3.175l-14.338 8.278a4.14 4.14 0 00-.451 6.869 4.14 4.14 0 002.52.855v-.009h-.001zM94.609 128.867l-14.338 8.278a4.14 4.14 0 104.138 7.169l14.338-8.278a4.14 4.14 0 10-4.137-7.16l-.001-.009zM80.271 53.253l14.337 8.279a4.08 4.08 0 002.061.555 4.139 4.139 0 002.069-7.724L84.4 46.085a4.14 4.14 0 10-4.141 7.161l.012.007zM242.127 137.144l-14.338-8.278a4.134 4.134 0 00-4.598.105 4.139 4.139 0 00-1.011 5.765 4.153 4.153 0 001.468 1.291l14.338 8.278a4.144 4.144 0 004.589-.3 4.136 4.136 0 001.304-4.869 4.142 4.142 0 00-1.755-2v.009l.003-.001zM196.382 34.264a4.081 4.081 0 002.069.555 4.14 4.14 0 003.593-2.069l8.278-14.338a4.136 4.136 0 00-.301-4.59 4.137 4.137 0 00-6.868.451l-8.278 14.338a4.138 4.138 0 001.507 5.653zM126.017 156.135a4.135 4.135 0 00-4.589.301 4.143 4.143 0 00-1.065 1.214l-8.278 14.338a4.137 4.137 0 001.512 5.655 4.075 4.075 0 002.069.555 4.136 4.136 0 003.593-2.069l8.278-14.338a4.135 4.135 0 00-.305-4.589 4.146 4.146 0 00-1.216-1.064l.001-.003z"
      />
      <path
        fill="#fff"
        d="M207.47 95.3c-3.23 0-6.452.322-9.618.962A39.66 39.66 0 00159.681 64.4a39.666 39.666 0 00-17.38 3.662 52.942 52.942 0 10-99.529 36.134 44.134 44.134 0 001.326 88.245H207.47a48.57 48.57 0 100-97.14z"
      />
    </svg>
  );
}

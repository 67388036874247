export function SvgHome(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="fi-sr-home"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      {...props}
    >
      <path
        id="Path_271"
        data-name="Path 271"
        d="M23.437,10.487,14.939,1.3a4,4,0,0,0-5.875,0L.581,10.484A2,2,0,0,0,0,11.894V21a3,3,0,0,0,3,3H7V18a4.985,4.985,0,0,1,2.628-4.374c.05-.028.1-.056.152-.083a4.969,4.969,0,0,1,.576-.243c.135-.049.272-.091.413-.128s.311-.07.471-.1c.088-.014.17-.044.26-.053.154-.015.3-.018.455-.019.016,0,.03-.005.045-.005h.018a5,5,0,0,1,.922.093c.034.007.068.01.1.017a5.015,5.015,0,0,1,.834.26c.038.015.076.029.114.046a4.964,4.964,0,0,1,.742.4l.111.074a5.038,5.038,0,0,1,.643.531c.032.031.062.062.093.094a5.16,5.16,0,0,1,.534.646l.068.1a5.062,5.062,0,0,1,.409.753c.013.032.024.064.037.1a4.961,4.961,0,0,1,.266.855.39.39,0,0,0,.011.067A4.974,4.974,0,0,1,17,18v6h4a3,3,0,0,0,3-3V11.879A2,2,0,0,0,23.437,10.487Z"
        transform="translate(8 7.985)"
      />
      <path
        id="Path_272"
        data-name="Path 272"
        d="M12,15a3,3,0,0,0-3,3v6h6V18A3,3,0,0,0,12,15Z"
        transform="translate(8 7.985)"
      />
    </svg>
  );
}

export function SvgInsightsGraph(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path d="M19.167 18.333H2.5a.833.833 0 0 1-.833-.833V.833A.833.833 0 1 0 0 .833V17.5A2.5 2.5 0 0 0 2.5 20h16.667a.833.833 0 0 0 0-1.667Z" />
      <path d="M12.5 16.667a.833.833 0 0 0 .833-.833V10a.833.833 0 1 0-1.667 0v5.833a.833.833 0 0 0 .834.834Z" />
      <path d="M5.833 16.667a.833.833 0 0 0 .833-.833V10a.833.833 0 1 0-1.667 0v5.833a.833.833 0 0 0 .834.834Z" />
      <path d="M15.833 16.667a.833.833 0 0 0 .833-.833v-10a.833.833 0 1 0-1.667 0v10a.833.833 0 0 0 .834.833Z" />
      <path d="M9.166 16.667a.833.833 0 0 0 .833-.833v-10a.833.833 0 0 0-1.667 0v10a.833.833 0 0 0 .834.833Z" />
    </svg>
  );
}

export function SvgUser(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 20" {...props}>
      <g transform="translate(-3)">
        <circle
          data-name="Ellipse 590"
          cx="5"
          cy="5"
          r="5"
          transform="translate(5.5)"
        />
        <path
          data-name="Path 1530"
          d="M10.5 11.667a7.508 7.508 0 0 0-7.5 7.5.833.833 0 0 0 .833.833h13.334a.833.833 0 0 0 .833-.833 7.508 7.508 0 0 0-7.5-7.5Z"
        />
      </g>
    </svg>
  );
}

export function SvgHelpQuestion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path d="M10 0a10 10 0 1 0 10 10A10 10 0 0 0 10 0Zm0 16.667a.833.833 0 1 1 .833-.833.833.833 0 0 1-.833.833Zm1.608-6.245a1.652 1.652 0 0 0-.775 1.459v.619a.833.833 0 0 1-1.667 0v-.619A3.3 3.3 0 0 1 10.8 8.964a1.667 1.667 0 0 0 .833-1.771A1.687 1.687 0 0 0 10.3 5.864 1.667 1.667 0 0 0 8.333 7.5a.833.833 0 0 1-1.667 0 3.333 3.333 0 1 1 4.942 2.921Z" />
    </svg>
  );
}

export function SvgSettingsGear(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.995 20" {...props}>
      <path d="M.337 15a2.5 2.5 0 0 0 3.417.917l.371-.214a7.481 7.481 0 0 0 2.372 1.369v.428a2.5 2.5 0 1 0 5 0v-.428a7.481 7.481 0 0 0 2.374-1.372l.373.215a2.501 2.501 0 1 0 2.5-4.333l-.37-.213a7.583 7.583 0 0 0 0-2.742l.37-.213a2.501 2.501 0 1 0-2.5-4.333l-.371.214a7.481 7.481 0 0 0-2.376-1.368V2.5a2.5 2.5 0 1 0-5 0v.427A7.481 7.481 0 0 0 4.124 4.3l-.373-.216a2.501 2.501 0 1 0-2.5 4.333l.37.213a7.583 7.583 0 0 0 0 2.742l-.37.213A2.505 2.505 0 0 0 .337 15Zm8.66-8.333A3.333 3.333 0 1 1 5.664 10a3.333 3.333 0 0 1 3.333-3.333Z" />
    </svg>
  );
}

export function SvgSignOut(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path d="M5.833 18.333H4.167a2.5 2.5 0 0 1-2.5-2.5V4.167a2.5 2.5 0 0 1 2.5-2.5h1.666a.833.833 0 0 0 0-1.667H4.167A4.172 4.172 0 0 0 0 4.167v11.666A4.172 4.172 0 0 0 4.167 20h1.666a.833.833 0 0 0 0-1.667Z" />
      <path d="m15.448 15.589 3.819-3.822a2.506 2.506 0 0 0 0-3.535l-3.819-3.821a.834.834 0 1 0-1.181 1.179l3.58 3.577H5a.833.833 0 1 0 0 1.667h12.848l-3.581 3.577a.833.833 0 1 0 1.178 1.178Z" />
    </svg>
  );
}

export function SvgHamburger(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" {...props}>
      <g transform="translate(0 -4)">
        <rect width="24" height="2" rx="1" transform="translate(0 11)" />
        <rect width="24" height="2" rx="1" transform="translate(0 4)" />
        <rect width="24" height="2" rx="1" transform="translate(0 18)" />
      </g>
    </svg>
  );
}

import { css } from '@emotion/react';
import { theme } from '@web/features/theme';

export const defaultButton = css`
  cursor: pointer;
  pointer-events: auto;
  display: inline-flex;
  align-items: center;
  justify-content: start;
  text-align: center;
  font-weight: ${theme.fontWeights.semibold};
  font-size: ${theme.fontSizes.md};
  line-height: ${theme.lineHeights.tall};
  border-radius: ${theme.radii.full};
  border: none;
  background-color: ${theme.colors.primary};
  box-shadow: ${theme.shadows.md};
  color: ${theme.colors.whiteAlpha[900]};
  padding: ${theme.space[2]} ${theme.space[8]};

  &.primary {
    background-color: ${theme.colors.primary};
  }

  &.secondary {
    background-color: ${theme.colors.secondary};
  }

  &.highlight {
    background-color: ${theme.colors.highlight};
  }

  &.muted {
    background-color: ${theme.colors.muted};
  }

  &.error {
    background-color: ${theme.colors.error};
  }

  &.success {
    background-color: ${theme.colors.success};
  }

  &.info {
    color: ${theme.colors.blackAlpha[900]};
    background-color: ${theme.colors.info};
  }

  &.warning {
    background-color: ${theme.colors.warning};
  }

  &.pill {
    border-radius: ${theme.radii.full};
  }

  &.alt {
    background-color: ${theme.colors.buttons.highlight};
    color: ${theme.colors.navbar.active};

    svg {
      fill: ${theme.colors.navbar.active};
      margin-right: 0;
    }
  }

  &.block {
    display: block;
    width: 100%;
  }

  &.disabled,
  &[disabled='true'] {
    cursor: not-allowed;
    pointer-events: none;
    background-color: ${theme.colors.gray[500]};
  }

  svg {
    align-self: center;
    order: -1;
    height: 100%;

    &:not(:only-child) {
      margin-right: ${theme.space[2]};
    }
  }
`;

import * as styles from '@web/components/menu/menu.styles';
import { SvgBackButton } from '@web/components/svgs/layout-icons';
import { fadeInDown, variantProps } from '@web/features/theme';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import * as React from 'react';

/**
 * # BackButton
 * @packageDescription
 *
 * Main navbar navigation for the app
 */
export function BackButton({
  icon = <SvgBackButton />,
  backTo,
}: {
  icon: React.ReactNode;
  backTo?: string | null | ((...args: unknown[]) => unknown);
}) {
  const router = useRouter();
  const goBack = () => {
    switch (typeof backTo) {
      case 'string':
        router.push(backTo);
        break;
      case 'function':
        backTo();
        break;
      default:
        router.back();
    }
  };

  return (
    <motion.button
      aria-label="go back"
      onClick={goBack}
      css={styles.menuButton}
      variants={fadeInDown}
      {...variantProps}
    >
      {icon}
    </motion.button>
  );
}

import { css } from '@emotion/react';
import { Button } from '@web/components/button';
import {
  listAnimation,
  listChildAnimation,
  theme,
  variantProps,
  variants,
} from '@web/features/theme';
import { motion } from 'framer-motion';
import Link from 'next/link';
import * as React from 'react';

type LinkOrButton<T> =
  | { renderLink: (item?: T) => string; onClick?: never }
  | {
      onClick: (
        evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        item: T,
      ) => unknown;
      renderLink?: never;
    };

type DefaultProps<T> = LinkOrButton<T> & {
  data: Array<T> | null;
  emptyText: string;
  withCreate?: boolean;
  createText?: string;
  createLink?: string;
  children?: React.ReactNode;
};

export type ButtonListProps<T> = DefaultProps<T> &
  (
    | {
        renderItem: (item: T) => React.ReactNode;
      }
    | {
        getKey: (item: T) => string;
        getText: (item: T) => string;
        getBackground: (item: T) => string | null | undefined;
      }
  );

/**
 * # Button List
 *
 * Used to display a list of buttons.
 * Generally used in `/admin` pages
 *
 * @export
 * @template T
 * @param {ButtonListProps<T>} {
 *   withCreate = true,
 *   data,
 *   createText,
 *   createLink,
 *   renderLink,
 *   onClick,
 *   emptyText,
 *   children,
 *   ...rest
 * }
 * @return {JSX.Element}
 */
export function ButtonList<T>({
  withCreate = true,
  data,
  createText,
  createLink,
  renderLink,
  onClick,
  emptyText,
  children,
  ...rest
}: ButtonListProps<T>) {
  return (
    <motion.ul css={styles.list} variants={listAnimation} {...variantProps}>
      {withCreate && (
        <motion.li variants={listChildAnimation} {...variantProps}>
          <Link href={createLink || ''} passHref legacyBehavior>
            <Button
              key="create"
              css={styles.listItem}
              style={{
                backgroundImage: `${variants.gradients.blackToTransparent}`,
                flex: '1 1 100%',
              }}
            >
              {createText || 'Create your Own!'}
            </Button>
          </Link>
        </motion.li>
      )}

      {children}

      {data?.length ? (
        data.map((item) =>
          'renderItem' in rest ? (
            rest.renderItem(item)
          ) : (
            <motion.li
              key={rest.getKey(item)}
              variants={listChildAnimation}
              {...variantProps}
            >
              {renderLink && (
                <Link href={renderLink(item)} passHref legacyBehavior>
                  <Button
                    css={styles.listItem}
                    style={{
                      backgroundImage: `${
                        variants.gradients.blackToTransparent
                      }, url('${rest.getBackground(item)}')`,
                    }}
                  >
                    {rest.getText(item)}
                  </Button>
                </Link>
              )}

              {onClick && (
                <Button
                  css={styles.listItem}
                  style={{
                    backgroundImage: `${
                      variants.gradients.blackToTransparent
                    }, url('${rest.getBackground(item)}')`,
                  }}
                  onClick={(e) => onClick(e, item)}
                >
                  {rest.getText(item)}
                </Button>
              )}
            </motion.li>
          ),
        )
      ) : (
        <motion.li variants={listChildAnimation} {...variantProps}>
          <Button
            css={styles.listItem}
            style={{
              backgroundImage: `${variants.gradients.blackToTransparent}`,
              flex: '1 1 100%',
            }}
            className="empty"
          >
            {emptyText || 'No items found.'}
          </Button>
        </motion.li>
      )}
    </motion.ul>
  );
}

export const styles = {
  list: css`
    display: flex;
    gap: ${theme.space[4]};
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
  `,
  listItem: css`
    flex: 1 1 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: none;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: ${theme.colors.muted};
    font-size: ${theme.fontSizes.md};
    line-height: ${theme.lineHeights.tall};
    font-family: ${theme.fonts.heading};
    letter-spacing: ${theme.letterSpacings.wide};

    &.empty {
      pointer-events: none;
      background-color: ${theme.colors.muted};
    }

    button {
      display: block;
      height: 100%;
      width: 100%;
    }

    svg {
      margin-right: ${theme.space[2]};
    }
  `,
};

/* eslint-disable no-nested-ternary */
import { css, SerializedStyles } from '@emotion/react';
import { Menu } from '@web/components/menu/menu';
import * as menuStyles from '@web/components/menu/menu.styles';
import { SvgHome } from '@web/components/svgs/menu-icons';
import {
  colors,
  pageTransition,
  theme,
  variantProps,
} from '@web/features/theme';
import { AnimatePresence, motion } from 'framer-motion';
import Head from 'next/head';
import { useRouter } from 'next/router';
import * as React from 'react';
import { BackButton } from './back-button';
import { ShareModal } from './share-modal';

export type LayoutConfig = {
  children: React.ReactNode;
  withMenu?: boolean;
  withShare?: boolean;
  withHomeButton?: boolean;
  withBackButton?: boolean;
  backButtonIcon?: React.ReactNode;
  backTo?: string | ((...args: unknown[]) => unknown) | null;
  withBlur?: boolean;
  backgroundImage?: string;
  background?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  fixed?: boolean;
  customCss?: SerializedStyles;
};

export function toggleLoadingScreen(value = false) {
  if (typeof document !== 'undefined') {
    const body = document?.getElementsByTagName('body')[0];

    const isLoading = body.className.includes('loading');

    if (value) {
      body.className = isLoading ? body.className : `${body.className} loading`;
    } else {
      body.className = body.className.replace('loading', '');
    }
  }
}

/**
 * # BaseLayout
 *
 * Provides a base layout for all other layouts and non-authenticated users
 */
export function BaseLayout({
  children,
  withMenu = true,
  withShare = false,
  withBackButton = false,
  withHomeButton = false,
  backTo = null,
  withBlur = false,
  fixed = false,
  backgroundImage,
  background = '',
  fullWidth = false,
  fullHeight = false,
  customCss,
  backButtonIcon,
}: LayoutConfig) {
  const { pathname } = useRouter();

  return (
    <>
      <Head>
        <title>{process.env.NEXT_PUBLIC_APP_NAME}</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />

        <style>
          {`body {
              z-index: 1;
              height: 100%;

              background-attachment: ${fixed ? 'fixed' : 'initial'};
              background: ${colors.background};
              background-color: ${colors.background};
              background-image: linear-gradient(to bottom, #3e1d51, #5438a0);
              ${background ? `background: ${background};` : ''}
              ${
                backgroundImage
                  ? `background-image: url('${
                      backgroundImage.startsWith('http')
                        ? backgroundImage
                        : `/images/${backgroundImage}`
                    }'), linear-gradient(to bottom, #3e1d51, #5438a0);
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            `
                  : ''
              }
              ${customCss}

            }`}

          {fullWidth
            ? `body, main {
                padding-left: 0 !important;
                padding-right: 0 !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
              }`
            : ''}

          {fullHeight
            ? `body, main {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
              }`
            : ''}

          {withBlur
            ? `body::before {
                content: '';
                position: fixed;
                top:0;
                left:0;
                right:0;
                bottom:0;
                height: 100%;
                width: 100%;
                z-index: -1;
                background-color: ${colors.blackAlpha[400]};
                filter: blur(0.3em);
              }

              @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
                body::before{
                  background-color: ${colors.blackAlpha[300]};
                  backdrop-filter: blur(0.3em);
                }
              }`
            : ''}
        </style>
      </Head>

      {/* Only one at a time - Order is important here, feel free to refactor */}
      <motion.header css={menuStyles.header}>
        {withBackButton ? (
          <BackButton backTo={backTo} icon={backButtonIcon} />
        ) : withHomeButton ? (
          <BackButton backTo="/" icon={<SvgHome />} />
        ) : withMenu ? (
          <Menu />
        ) : null}

        {withShare ? <ShareModal /> : null}
      </motion.header>

      <AnimatePresence mode="wait">
        <motion.main
          variants={pageTransition}
          {...variantProps}
          key={pathname}
          css={
            fullWidth || fullHeight
              ? []
              : [
                  css`
                    margin-top: calc(
                      env(safe-area-inset-top) + ${theme.space[8]}
                    );
                  `,
                ]
          }
        >
          {children}
        </motion.main>
      </AnimatePresence>
    </>
  );
}

import { UserData } from '@core/users/users';
import { DONATE_LINK } from '@core/utils/env';
import { css } from '@emotion/react';
import { Button } from '@web/components/button';
import {
  SvgHamburger,
  SvgHelpQuestion,
  SvgHome,
  SvgInsightsGraph,
  SvgSettingsGear,
  SvgSignOut,
  SvgUser,
} from '@web/components/svgs/menu-icons';
import { useAuth } from '@web/features/auth/use-auth';
import { createInsightsUrl } from '@web/features/insights/insights-url';
import {
  fadeInDown,
  listAnimation,
  listChildAnimation,
  theme,
  variantProps,
} from '@web/features/theme';
import { useUserData } from '@web/features/users/use-user-data';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useIsRunningAsIOSApp } from '@web/utils/ios-app';
import * as styles from './menu.styles';

export const navLinks = (userData?: UserData) => [
  {
    href: '/',
    label: 'Home',
    icon: <SvgHome />,
  },
  {
    href: createInsightsUrl({ dateStr: userData?.last_active }),
    label: 'Insights',
    icon: <SvgInsightsGraph />,
  },
  {
    href: '/about',
    label: 'About',
    icon: <SvgUser />,
  },
  {
    href: `/help`,
    label: 'Help',
    icon: <SvgHelpQuestion />,
  },
  {
    href: '/settings',
    label: 'Settings',
    icon: <SvgSettingsGear />,
  },
];

/**
 * # Menu
 *
 * Main navbar navigation for the app
 */
export function Menu() {
  const { pathname } = useRouter();
  const [_, { signOut }] = useAuth();
  const [{ userData }] = useUserData();

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleNav = () => setIsOpen((prev) => !prev);

  const isActive = (href: string) =>
    pathname === href ? 'active' : 'not-active';

  const [isRunningAsIOSApp] = useIsRunningAsIOSApp();

  React.useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  return (
    <>
      <motion.button
        aria-label="menu"
        onClick={toggleNav}
        css={styles.menuButton}
        variants={fadeInDown}
        {...variantProps}
      >
        <SvgHamburger />
      </motion.button>

      <motion.nav
        css={styles.nav}
        className={isOpen ? 'active' : 'disabled'}
        variants={listAnimation}
        initial="initial"
        animate={isOpen ? 'animate' : 'initial'}
        exit="exit"
      >
        {navLinks(userData).map(({ href, label, icon }) => (
          <Link href={href} key={label} passHref legacyBehavior>
            <motion.a
              key={label}
              tabIndex={0}
              css={styles.navLink}
              className={isActive(href)}
              variants={listChildAnimation}
              onClick={toggleNav}
            >
              <MenuIcon icon={icon} label={label} />
              {label}
            </motion.a>
          </Link>
        ))}

        <Button
          onClick={signOut}
          className="info"
          css={css`
            ${styles.navLink}
            border-radius: 0;
            padding: ${theme.space[4]} ${theme.space[4]};
            color: ${theme.colors.whiteAlpha[900]} !important;
            display: ${isOpen ? 'block' : 'none'};
          `}
        >
          <SvgSignOut />
          Sign out
        </Button>

        {!isRunningAsIOSApp && (
          <a
            href={DONATE_LINK}
            target="_blank"
            rel="noopener noreferrer"
            className="info"
            css={css`
              ${styles.navLink}
              /* align-self: flex-end; */
              justify-self: flex-end;
              border-radius: 0;
              padding: ${theme.space[4]} ${theme.space[4]};
              display: ${isOpen ? 'block' : 'none'};
              background-color: ${theme.colors.info} !important;
              color: ${theme.colors.whiteAlpha[900]} !important;
              text-align: center;
              margin-top: auto;
            `}
          >
            Donate to TILT
          </a>
        )}
      </motion.nav>
    </>
  );
}

export function MenuIcon({
  icon,
  label,
}: {
  icon: React.ReactNode;
  label?: string;
}) {
  return (
    <span
      role="img"
      aria-label={label ? String(label) : 'icon'}
      css={styles.icon}
    >
      {icon}
    </span>
  );
}
